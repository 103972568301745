import {createApp} from 'vue'
import App from './App.vue'
import router from './router';

import {IonicVue} from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';

import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'

const app = createApp(App)
    .use(IonicVue)
    .use(router)
    .use(VueViewer, {
        defaultOptions: {
            zIndex: 9999,
            movable: false,
            fullscreen: false,
            scalable: false
        }
    });

router.isReady().then(() => {
    app.mount('#app');
});

app.config.globalProperties.$filters = {
    formatOrderDate: function (value: any) {
        console.log(value);
        const orderDate = new Date(Date.parse(value));
        return orderDate.getFullYear() + '-'
            + (orderDate.getMonth() + 1) + '-'
            + orderDate.getDate() + ' '
            + orderDate.getHours() + ':'
            + orderDate.getMinutes() + ':'
            + orderDate.getSeconds();
    },
    activityStatus: function (value: any) {
        if (value === 1) {
            return "未开始";
        } else if (value === 2) {
            return "进行中";
        } else if (value === 3) {
            return "已结束";
        }
        return "";
    },
    payMethod: function (value: any) {
        if (value === 1) {
            return "支付宝";
        } else if (value === 2) {
            return "微信";
        } else if (value === 3) {
            return "线下打款";
        }
        return "";
    },
    billStatus: function (value: any) {
        if (value === 0) {
            return "未支付";
        } else if (value === 1) {
            return "已支付";
        }
        return "";
    },
    getPlatformType: function (value: any) {
        return value === 1 ? "meituan" : "eleme";
    }
}
