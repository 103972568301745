import {createRouter, createWebHistory} from '@ionic/vue-router';
import {RouteRecordRaw} from 'vue-router';
import Tabs from '../views/Tabs.vue'

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        redirect: '/tabs/tab1'
    },
    {
        path: '/tabs/',
        component: Tabs,
        children: [
            {
                path: '',
                redirect: '/tabs/tab1'
            },
            {
                path: 'tab1',
                component: () => import('@/views/Home.vue')
            },
            {
                path: 'tab2',
                component: () => import('@/views/BillList.vue')
            },
            {
                path: 'tab3',
                component: () => import('@/views/Mine.vue')
            }
        ]
    },
    {
        path: '/billDetail/:billId',
        component: () => import('@/views/BillDetail.vue')
    },
    {
        path: '/login',
        component: () => import('@/views/Login.vue')
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
